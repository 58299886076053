import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { filter, map, switchMap } from 'rxjs';
import { ButtonComponent } from '@aprova-digital/design-system';

import { Types } from '../../../../../domain/types.domain';
import { GetCepDetailsOutput } from '../../../../../domain/city/dtos/get-cep-details.output';

import { RadioComponent } from '../../../../components/radio/radio.component';
import { InputComponent } from '../../../../components/input/input.component';
import { CheckboxComponent } from '../../../../components/checkbox/checkbox.component';
import { RecaptchaComponent } from '../../../../components/recaptcha/recaptcha.component';

import { SearchCepProviderInstruction } from '../../../../../builder/instructions/providers/search-cep.provider.instruction';

import { AccountFormHandler } from '../../account-form.handler';

@Component({
  selector: 'app-address-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    RadioComponent,
    InputComponent,
    CheckboxComponent,
    ButtonComponent,
    RecaptchaComponent,
  ],
  providers: [new SearchCepProviderInstruction()],
  templateUrl: './address-form.component.html',
  styleUrl: './address-form.component.scss',
})
export class AddressFormComponent {
  private readonly _searchCepProvider = inject(Types.SearchCepProvider);
  private readonly _accountFormHandler = inject(AccountFormHandler);

  @Input()
  public isLoading: boolean = false;

  @Input()
  public hideRecaptcha: boolean = false;

  @Input()
  public blockedFields: string[] = [];

  @Input()
  public isLastStep: boolean = false;

  @Output()
  public setStepValidation = new EventEmitter<boolean>();

  @Output()
  public changeRecaptcha = new EventEmitter<string>();

  @ViewChild('inputStreet', { static: false })
  public inputStreet: InputComponent | undefined;

  @ViewChild('inputNumber', { static: false })
  public inputNumber: InputComponent | undefined;

  @ViewChild('inputNeighborhood', { static: false })
  public inputNeighborhood: InputComponent | undefined;

  public loading = signal(false);
  public hasError = signal(false);
  public errorMessage = signal('');

  public noNumberAddress = signal(false);

  public form = new FormGroup({
    cep: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(144)],
    }),
    rua: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(255)],
    }),
    estado: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(144)],
    }),
    cidade: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(144)],
    }),
    bairro: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(255)],
    }),
    numero: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(144)],
    }),
    complemento: new FormControl('', {
      nonNullable: true,
      validators: [Validators.maxLength(144)],
    }),
    termos: new FormControl(false),
    recaptchaReactive: new FormControl<string | null>(null),
  });

  ngOnInit(): void {
    this.bindCepFields();

    if (this.isLastStep) {
      this.form.controls.termos.setValidators([Validators.requiredTrue]);
      this.form.controls.termos.setValue(false);

      if (!this.hideRecaptcha) {
        this.form.registerControl(
          'recaptchaReactive',
          new FormControl('', {
            nonNullable: true,
            validators: [Validators.required],
          })
        );
      }
    }

    this.form.valueChanges.subscribe((val) => {
      this.setStepValidation.emit(this.form.valid);
      this._accountFormHandler.setAddressInfo(this.form.value);

      if (!this.hideRecaptcha && val.recaptchaReactive) {
        this.changeRecaptcha.emit(val.recaptchaReactive);
      }
    });

    const addressData = this._accountFormHandler.getAddressInfo();
    if (addressData) {
      this.form.patchValue(addressData);
    }

    this.form.updateValueAndValidity();
  }

  isFieldBlocked(name: string): boolean {
    return this.blockedFields.includes(name);
  }

  toogleNoNumberAddress(): void {
    this.noNumberAddress.update((val) => !val);
    this.form.get('numero')?.setValue('S/N');

    if (this.noNumberAddress()) {
      this.form.get('numero')?.disable();
      this.form.get('numero')?.setValidators([]);
      this.form
        .get('complemento')
        ?.setValidators([Validators.required, Validators.maxLength(144)]);
    } else {
      this.form.get('numero')?.enable();
      this.form
        .get('numero')
        ?.setValidators([Validators.required, Validators.maxLength(144)]);
      this.form.get('complemento')?.setValidators([]);
    }

    this.form.updateValueAndValidity();
  }

  private bindCepFields(): void {
    this.form.controls.cep.valueChanges
      .pipe(
        map((cep) => cep.replace('-', '')),
        filter((cep) => cep.length === 8),
        switchMap((cep) => this._searchCepProvider.handle(cep))
      )
      .subscribe((address: GetCepDetailsOutput) => {
        this.form.patchValue(address);

        const inputComponent = this.getNextAddressInputToFill(address);

        inputComponent?.inputRef?.nativeElement.focus();
      });
  }

  private getNextAddressInputToFill(
    address: GetCepDetailsOutput
  ): InputComponent | undefined {
    if (!address.rua) {
      return this.inputStreet;
    }

    if (!address.bairro) {
      return this.inputNeighborhood;
    }

    return this.inputNumber;
  }
}
