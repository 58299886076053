import { Component, ViewChild, inject, signal } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonComponent } from '@aprova-digital/design-system';
import { combineLatest, filter, map, switchMap } from 'rxjs';

import { City } from '../../../domain/city/entities/city.entity';
import { GetCepDetailsOutput } from '../../../domain/city/dtos/get-cep-details.output';

import { StepperComponent } from '../../components/stepper/stepper.component';
import { InputComponent } from '../../components/input/input.component';
import { CheckboxComponent } from '../../components/checkbox/checkbox.component';
import { ButtonComponent as OldButtonComponent } from '../../components/button/button.component';

import { Types } from '../../../domain/types.domain';
import { UserRepositoryInstruction } from '../../../builder/instructions/repositories/user.repository.instructions';
import { UpdateAccountServiceInstruction } from '../../../builder/instructions/services/update-account.service.instruction';
import { SearchCepProviderInstruction } from '../../../builder/instructions/providers/search-cep.provider.instruction';

import { InitialCreateAccountInput } from '../../../domain/user/dtos/initial-create-account.input';
import { UpdateAccountInput } from '../../../domain/user/dtos/update-account.input';

import { AppHandler } from '../../app.handler';
import { AccountFormHandler } from '../create-account/account-form.handler';

@Component({
  selector: 'app-complete-registration',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    StepperComponent,
    InputComponent,
    ButtonComponent,
    CheckboxComponent,
    OldButtonComponent,
  ],
  providers: [
    AccountFormHandler,
    new SearchCepProviderInstruction(),
    new UserRepositoryInstruction(),
    new UpdateAccountServiceInstruction(),
  ],
  templateUrl: './complete-registration.component.html',
  styleUrl: './complete-registration.component.scss',
})
export class CompleteRegistrationComponent {
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);

  private readonly _searchCepProvider = inject(Types.SearchCepProvider);
  private readonly _updateAccountService = inject(Types.UpdateAccountService);

  private readonly _accountFormHandler = inject(AccountFormHandler);
  private readonly _appHandler = inject(AppHandler);

  @ViewChild('inputCep', { static: false })
  public inputCep: InputComponent | undefined;

  @ViewChild('inputStreet', { static: false })
  public inputStreet: InputComponent | undefined;

  @ViewChild('inputNumber', { static: false })
  public inputNumber: InputComponent | undefined;

  @ViewChild('inputNeighborhood', { static: false })
  public inputNeighborhood: InputComponent | undefined;

  public routeState = this._router.getCurrentNavigation()?.extras?.state ?? {};

  public cityEntity: City | undefined;
  public cityIdentity: string = this._route.snapshot.data['city']['identidade'];
  public cityConnection: string =
    this._route.snapshot.data['city']['connection'];

  public steps = [
    { label: 'Dados pessoais', valid: false },
    { label: 'Dados de endereço', valid: false },
  ];

  public userData?: InitialCreateAccountInput;
  public token = signal('');
  public redirectUri = signal('');

  public recaptchaReactive: string = '';
  public errorMessage: string = '';
  public hasError: boolean = false;

  public blockedFields: string[] = [];
  public isCertificateAuthRedirect = signal(false);
  public isBusinessAccount = signal(false);

  public noNumberAddress = signal(false);

  public error?: string = undefined;

  public profileForm = new FormGroup({
    type: new FormControl('personal', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(144)],
    }),
    firstName: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(144)],
    }),
    lastName: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(144)],
    }),
    email: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    }),
    cpf: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    phoneNumber: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  public addressForm = new FormGroup({
    cep: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(144)],
    }),
    rua: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(255)],
    }),
    estado: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(144)],
    }),
    cidade: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(144)],
    }),
    bairro: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(255)],
    }),
    numero: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(144)],
    }),
    complemento: new FormControl('', {
      nonNullable: true,
      validators: [Validators.maxLength(144)],
    }),
  });

  get isPersonalAccount(): boolean {
    return this.profileForm?.controls.type.value === 'personal';
  }

  get currentStep(): number {
    return this._accountFormHandler.getStep();
  }

  get isCurrentStepValid(): boolean {
    return this.steps[this.currentStep].valid;
  }

  ngOnInit(): void {
    this._route.data.subscribe({
      next: ({ city }) => {
        this.cityEntity = city;
        this.cityIdentity = city.identidade;
        this.cityConnection = city.connection;

        this._appHandler.setBackButtonHome(false);
        this._appHandler.setLoading(false);
      },
    });

    combineLatest([
      this.profileForm.valueChanges,
      this.addressForm.valueChanges,
    ]).subscribe(() => {
      this.steps[0].valid = this.profileForm.valid;
      this.steps[1].valid = this.addressForm.valid;
    });

    this.userData = this.routeState['userData'];
    this.token.set(this.routeState['token']);
    this.redirectUri.set(this.routeState['redirectUri']);

    if (!this.userData || !this.token()) {
      this.redirectToLogin();
    }

    this.fillOutForms();
    this._bindCepFields();
  }

  fillOutForms(): void {
    if (this.userData) {
      this.profileForm.patchValue({
        type: this.userData.type ?? 'personal',
        firstName: this.userData.userMetadata?.firstName ?? '',
        lastName: this.userData.userMetadata?.lastName ?? '',
        email: this.userData.email ?? '',
        cpf: this.userData.userMetadata?.cpf ?? '',
        phoneNumber: this.userData.userMetadata?.phoneNumber ?? '',
      });
      this.addressForm.patchValue({
        cep: this.userData.userMetadata?.cep ?? '',
        rua: this.userData.userMetadata?.rua ?? '',
        numero: this.userData.userMetadata?.numero ?? '',
        complemento: this.userData.userMetadata?.complemento ?? '',
        bairro: this.userData.userMetadata?.bairro ?? '',
        cidade: this.userData.userMetadata?.cidade ?? '',
        estado: this.userData.userMetadata?.estado ?? '',
      });
      this.profileForm.updateValueAndValidity();
      this.addressForm.updateValueAndValidity();
    }
  }

  redirectToLogin(): void {
    this._router.navigate([`/${this.cityIdentity}`]);
  }

  isFieldBlocked(name: string): boolean {
    return this.blockedFields.includes(name);
  }

  toogleNoNumberAddress(): void {
    this.noNumberAddress.update((val) => !val);
    this.addressForm.get('numero')?.setValue('');
    if (this.noNumberAddress()) {
      this.addressForm.get('numero')?.disable();
      this.addressForm.get('numero')?.setValidators([]);
      this.addressForm
        .get('complemento')
        ?.setValidators([Validators.required, Validators.maxLength(144)]);
    } else {
      this.addressForm.get('numero')?.enable();
      this.addressForm
        .get('numero')
        ?.setValidators([Validators.required, Validators.maxLength(144)]);
      this.addressForm.get('complemento')?.setValidators([]);
    }
    this.addressForm.updateValueAndValidity();
  }

  goToStep(stepNumber: number): void {
    if (stepNumber < 0) {
      this.redirectToLogin();
      return;
    }

    if (stepNumber > this.steps.length - 1) {
      this.onUpdateUser();
      return;
    }

    this._accountFormHandler.setStep(Math.max(stepNumber, 0));
  }

  setStepValidation(stepNumber: number, valid: boolean): void {
    if (this.steps[stepNumber]) {
      this.steps[stepNumber].valid = valid;
    }
  }

  onChangeStep(param: number): void {
    this.goToStep(this.currentStep + param);
  }

  onChangeRecaptcha(recaptchaReactive: string): void {
    this.recaptchaReactive = recaptchaReactive;
  }

  onUpdateUser(): void {
    this._appHandler.setLoading(true);

    const name =
      this.profileForm.value.type === 'business'
        ? this.profileForm.value.lastName
        : `${this.profileForm.value.firstName} ${this.profileForm.value.lastName}`;

    const data: UpdateAccountInput = {
      accessToken: this.token(),
      is_registration_complete: true,
      name,
      user_metadata: {
        name,
        given_name: this.profileForm.value.firstName,
        family_name: this.profileForm.value.lastName,
        telefone: this.profileForm.value.phoneNumber,
        cep: this.addressForm.value.cep,
        rua: this.addressForm.value.rua,
        numero: this.addressForm.value.numero || 'S/N',
        estado: this.addressForm.value.estado,
        cidade: this.addressForm.value.cidade,
        bairro: this.addressForm.value.bairro,
        complemento: this.addressForm.value.complemento,
      },
    };

    this._updateAccountService
      .handle(data)
      .then(() => {
        window.location.href = this.redirectUri();
      })
      .catch(() => {
        this._appHandler.setLoading(false);
        this._appHandler.setError(true);
      });
  }

  private _bindCepFields(): void {
    this.addressForm.controls.cep.valueChanges
      .pipe(
        map((cep) => cep.replace('-', '')),
        filter((cep) => cep.length === 8),
        switchMap((cep) => this._searchCepProvider.handle(cep))
      )
      .subscribe((address) => {
        this.addressForm.patchValue(address);

        const inputComponent = this.getNextAddressInputToFill(address);

        inputComponent?.inputRef?.nativeElement.focus();
      });
  }

  private getNextAddressInputToFill(
    address: GetCepDetailsOutput
  ): InputComponent | undefined {
    if (!address.rua) {
      return this.inputStreet;
    }

    if (!address.bairro) {
      return this.inputNeighborhood;
    }

    return this.inputNumber;
  }
}
