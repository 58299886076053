export default class DevSettings {
  production = false;

  citiesWhitelist = [
    {
      name: 'Aprovadesk - AD',
      identity: 'aprovadeskad',
      connection: 'AprovadeskAD',
    },
    {
      name: 'Modelo - AD',
      identity: 'modeload',
      connection: 'Username-Password-Authentication',
      loginUrl: '',
    },
  ];

  authenticationUrl = {
    v1: 'https://alt-develop.api.aprova.com.br/authentication-api/v1',
  };

  citiesUrl = {
    v1: 'https://alt-develop.api.aprova.com.br/cities',
  };

  usersUrl = {
    v2: 'https://alt-develop.api.aprova.com.br/users/v2',
  };

  recaptcha = {
    siteKey: '6Les3sEUAAAAAEw59z-wAQOt8no3d500fFYdbLf0',
  };

  webpki = {
    apiKey:
      'AiEAKi5hcHJvdmEuY29tLmJyLCoucHJlZmVpdHVyYXMubmV0QwBpcDQ6MTAuMC4wLjAvOCxpcDQ6MTI3LjAuMC4wLzgsaXA0OjE3Mi4xNi4wLjAvMTIsaXA0OjE5Mi4xNjguMC4wLzE2AwBQcm8AAAABOllvzNrFPUw4wm6rxLyvjD29Kx91wsF0BZRnAM52cdw23Sbn9VkenhbceiHRK+rXfVeQOKZXy0Rsq5sK47CAdSyfOYyV3YomgHeYTN3GT6uzhCcG+QX00HVFrh4aajLbp3eC74Z/HK8kAb9j0gFRM7SiEtRWiKFMNg5fQkmT6LoeBP//inKwePScYJfc3srB2Txycbvli6I02MSBOmwLNERA0xXYnJDte4eT5wT96YHBS5HOIfURRl0tw7DbC/TLqJRQVPxruIjuafbnk5/uMKoni2oHP8Ftk/kpcuts3WryaB9DN6hinbEhSCoH5E8D+j9fxGVb5A4ZYz/KM5Vqxw==',
    pluginUrl:
      'https://chrome.google.com/webstore/detail/web-pki/dcngeagmmhegagicpcmpinaoklddcgon',
  };

  mdChatClient = '724E7555A44B47A595657B00F34892D0';

  socialLogin = {
    google: {
      clientId:
        '151302971136-2qvflstuuqpe3j2f865bsrgh7cvjev02.apps.googleusercontent.com',
    },
  };
}
